import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="You May Also Find Me Via..." mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "email-added-here-async-to-discourage-scraping"
        }}>{`📩 Email`}</a>{` -- I do actually check email.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jdodsoncollins"
        }}>{`🐙 GitHub`}</a>{` -- Though please bear in mind most work of mine is closed-source.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/jollins"
        }}>{`🐦 Twitter`}</a>{` -- I rarely tweet but use my long follow list as a news/commentary feed, as well as a surprisingly good developer learning resource.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/jeremy-collins-37807911/"
        }}>{`🏢 LinkedIn`}</a>{` -- Kept up to date; consider this my online resume.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.goodreads.com/user/show/25277306-jeremy"
        }}>{`📚 Goodreads`}</a>{` -- These are my recent audiobook listens mostly.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      