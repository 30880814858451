import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi, I'm Jeremy 👱‍♂️
    </Text>
    <Text sx={{
      fontSize: [1, 1, 3]
    }} mdxType="Text">A dad, cat person, and forever a script kiddie 👶. </Text>
    <Text sx={{
      fontSize: [1, 1, 3],
      paddingTop: '0.2rem'
    }} mdxType="Text">Also a software developer using primarily <Text sx={{
        backgroundColor: `rgba(255, 255, 255, 0.8)`,
        boxShadow: `2px 0px 1px 3px rgba(255, 255, 255, 0.8)`,
        display: `inline`
      }} mdxType="Text"><Text sx={{
          color: `yellow`,
          display: `inline`,
          backgroundColor: `rgba(240, 219, 79, 0.125)`,
          boxShadow: `2px 0px 1px 3px rgba(240, 219, 79, 0.125)`
        }} mdxType="Text">JavaScript</Text></Text>, <Text sx={{
        backgroundColor: `rgba(255, 255, 255, 0.8)`,
        boxShadow: `2px 0px 1px 3px rgba(255, 255, 255, 0.8)`,
        display: `inline`
      }} mdxType="Text"><Text sx={{
          color: `darkred`,
          display: `inline`,
          backgroundColor: `rgba(248, 4, 5, 0.125)`,
          boxShadow: `2px 0px 1px 3px rgba(248, 4, 5, 0.125)`
        }} mdxType="Text">Ruby</Text></Text>, and <Text sx={{
        backgroundColor: `rgba(255, 255, 255, 0.8)`,
        boxShadow: `2px 0px 1px 3px rgba(255, 255, 255, 0.8)`,
        display: `inline`
      }} mdxType="Text"><Text sx={{
          color: `orange`,
          display: `inline`,
          backgroundColor: `rgba(252, 162, 62, 0.125)`,
          boxShadow: `2px 0px 1px 3px rgba(252, 162, 62, 0.125)`
        }} mdxType="Text">Swift</Text></Text>.</Text>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      